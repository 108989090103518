exports.components = {
  "component---src-pages-asiakaspalautteet-jsx": () => import("./../../../src/pages/asiakaspalautteet.jsx" /* webpackChunkName: "component---src-pages-asiakaspalautteet-jsx" */),
  "component---src-pages-ensiapukoulutukset-jsx": () => import("./../../../src/pages/ensiapukoulutukset.jsx" /* webpackChunkName: "component---src-pages-ensiapukoulutukset-jsx" */),
  "component---src-pages-ensiavun-kouluttajakurssi-jsx": () => import("./../../../src/pages/ensiavun-kouluttajakurssi.jsx" /* webpackChunkName: "component---src-pages-ensiavun-kouluttajakurssi-jsx" */),
  "component---src-pages-hinnasto-jsx": () => import("./../../../src/pages/hinnasto.jsx" /* webpackChunkName: "component---src-pages-hinnasto-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-ilmoittautumisesta-jsx": () => import("./../../../src/pages/kiitos-ilmoittautumisesta.jsx" /* webpackChunkName: "component---src-pages-kiitos-ilmoittautumisesta-jsx" */),
  "component---src-pages-kiitos-jsx": () => import("./../../../src/pages/kiitos.jsx" /* webpackChunkName: "component---src-pages-kiitos-jsx" */),
  "component---src-pages-kotihoito-ja-kotisairaanhoito-jsx": () => import("./../../../src/pages/kotihoito-ja-kotisairaanhoito.jsx" /* webpackChunkName: "component---src-pages-kotihoito-ja-kotisairaanhoito-jsx" */),
  "component---src-pages-rekry-jsx": () => import("./../../../src/pages/rekry.jsx" /* webpackChunkName: "component---src-pages-rekry-jsx" */),
  "component---src-pages-tietoa-meista-index-jsx": () => import("./../../../src/pages/tietoa-meista/index.jsx" /* webpackChunkName: "component---src-pages-tietoa-meista-index-jsx" */),
  "component---src-pages-tietoa-meista-jaana-jsx": () => import("./../../../src/pages/tietoa-meista/jaana.jsx" /* webpackChunkName: "component---src-pages-tietoa-meista-jaana-jsx" */),
  "component---src-pages-tietoa-meista-seikku-jsx": () => import("./../../../src/pages/tietoa-meista/seikku.jsx" /* webpackChunkName: "component---src-pages-tietoa-meista-seikku-jsx" */),
  "component---src-pages-yksityinen-kotihoito-espoo-jsx": () => import("./../../../src/pages/yksityinen-kotihoito-espoo.jsx" /* webpackChunkName: "component---src-pages-yksityinen-kotihoito-espoo-jsx" */),
  "component---src-pages-yrityksille-jsx": () => import("./../../../src/pages/yrityksille.jsx" /* webpackChunkName: "component---src-pages-yrityksille-jsx" */)
}

